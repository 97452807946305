.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: var(--height-navbar);
  background: var(--color-background);
  box-sizing: border-box;
}

.title {
  user-select: none;
  margin: 0;
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 48px;
  white-space: initial;
  display: flex;
}

.title b {
  font-weight: 500;
}

.header-item {
  display: flex;
  height: var(--height-navbar-inner);
}
