.question {
  max-width: 80vw;
  font-weight: 300;
  letter-spacing: 0;
  font-size: min(6vw, 8vh);
  line-height: min(8vw, 11vh);
  text-align: center;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
  margin-block: 0;
}
