.play-container {
  position: relative;
}
.mycelium-network {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 75vh;
  z-index: -1;
  opacity: 0.4;
}
