.modal-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
}

.modal {
  position: relative;
  border-radius: var(--radius);
  background-color: var(--color-background);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  max-width: 825px;
  background-image: url('images/modalShrooms.png');
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 3rem 7.8rem 5rem;
}
