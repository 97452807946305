* {
  box-sizing: border-box;
}

html {
  --color-background: #623e3e;
  --color-background-disabled: #764d48;
  --color-text: #f5d1c3;
  --color-text-disabled: #a16868;
  --color-text-busy: #a97575;
  --color-button: #d98575;
  /* TODO rename to grays */
  --color-disabled: #a16868;
  --color-button-hover: #eaa285;
  --color-button-disabled: #c78a73;
  --color-input-disabled: #ba938a;
  --color-error: #ea7070;
  --height-navbar: 80px;
  --height-navbar-inner: 48px;
  --radius: 8px;
  --box-shadow: 0 2px 4px 0 rgba(240, 188, 168, 0.4);
  --box-shadow-dark: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --responsive-small: 786px;
  --font-family: 'Montserrat', sans-serif;
  background: var(--color-background);
  color: var(--color-text);
}

body {
  margin: 0;
  font-family: var(--font-family);
}

svg {
  fill: currentColor;
}

.body {
  width: 100%;
  height: calc(100vh - var(--height-navbar));
}

.flex-center {
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
}

.flex-row {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.flex-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.hover {
  cursor: pointer;
  user-select: none;
}

.hover:hover {
  color: white;
}

.hover.disabled {
  cursor: initial;
  color: var(--color-disabled);
}

.hover.disabled:hover {
  color: var(--color-disabled);
}

div[class^='arrow-'] {
  font-size: 5em;
}

.arrow-left:before {
  content: '\2039';
  padding: 0.2em;
}

.arrow-right:after {
  content: '\203A';
  padding: 0.2em;
}

.p1 {
  padding: 1rem;
}

.w80 {
  display: inline-block;
  width: 5rem;
}

.w120 {
  display: inline-block;
  width: 7.5rem;
}

.hide-on-small {
  visibility: hidden;
}
.show-on-small {
  display: initial;
}

.spacer {
  flex: 1;
}

.page-container {
  padding: 15px 25px;
}

a {
  text-decoration: none;
}

/*@media only screen and (min-width: 787px) {*/
/*  .hide-on-small {*/
/*    display: initial;*/
/*  }*/
/*  .show-on-small {*/
/*    display: none;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 786px) {*/
/*  .hide-on-small {*/
/*    display: none;*/
/*  }*/
/*  .show-on-small {*/
/*    display: initial;*/
/*  }*/
/*}*/
