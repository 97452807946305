.tabs {
  list-style: none;
  padding: 0;
  position: relative;
}

.slider {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: var(--color-text);
  transform-origin: 0 0;
  transition: all 0.4s ease-in-out;
}

.tab {
  color: var(--color-text);
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  user-select: none;
  transition: all 0.4s ease-in-out;
  padding: 15px;
}
